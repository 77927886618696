import React, { ReactNode, createContext, useState } from 'react'

export type ConfirmModalVariant = 'confirm' | 'information'

export type ModalMessage = {
  icon?: string
  tittle?: string
  description?: string
}

type openModalType = (setting: {
  type: ConfirmModalVariant
  message?: ModalMessage
  onConfirm?: () => void
}) => void

export type ModalContextType = {
  isModalOpen: boolean
  setIsModalOpen: (value: boolean) => void
  variant: ConfirmModalVariant
  openModal: openModalType
  message: ModalMessage
  onConfirm: () => void
  setOnConfirm: React.Dispatch<React.SetStateAction<() => void>>
}

export const ModalContext = createContext<ModalContextType>({
  isModalOpen: false,
  setIsModalOpen: () => {},
  variant: 'confirm',
  openModal: () => {},
  message: {},
  onConfirm: () => {},
  setOnConfirm: () => {},
})

type Props = {
  children: ReactNode
}

export const ModalContextProvider: React.FC<Props> = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [variant, setVariant] = useState<ConfirmModalVariant>('confirm')
  const [message, setMessage] = useState<ModalMessage>({})
  const [onConfirm, setOnConfirm] = useState<() => void>(() => {})

  const openModal: openModalType = ({ type, message, onConfirm }) => {
    setVariant(type)
    if (message) setMessage(message)
    if (onConfirm) setOnConfirm(() => onConfirm)
    setIsModalOpen(true)
  }

  return (
    <ModalContext.Provider
      value={{
        isModalOpen,
        setIsModalOpen,
        variant,
        openModal,
        message,
        onConfirm,
        setOnConfirm,
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}
