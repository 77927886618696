import { TextField, TextFieldProps, Typography } from '@mui/material'
import InfoTooltipWithText from 'components/InfoTooltipWithText/InfoTooltipWithText'
import { Path, UseFormReturn } from 'react-hook-form'

type CustomTextFieldProps<T extends { [key: string]: any }> = {
  formControl: UseFormReturn<T, any, undefined>
  fieldName: Path<T>
  label: string
  infoText?: string
} & TextFieldProps

const CustomTextField = <T extends { [key: string]: any }>(
  props: CustomTextFieldProps<T>
) => {
  const { formControl, fieldName, label, infoText, ...rest } = props

  return (
    <TextField
      {...formControl.register(fieldName)}
      error={!!formControl.formState.errors[fieldName]}
      helperText={
        <>
          {!!infoText ? <InfoTooltipWithText message={infoText} /> : null}
          {formControl.formState.errors[fieldName]?.message
            ? String(formControl.formState.errors[fieldName]?.message)
            : null}
        </>
      }
      label={label}
      size="small"
      {...rest}
    />
  )
}

export default CustomTextField
