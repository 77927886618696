import { FormControl, FormControlTypeMap, FormHelperText } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import InfoTooltipWithText from 'components/InfoTooltipWithText/InfoTooltipWithText'
import { Path, UseFormReturn } from 'react-hook-form'

type CustomTextFieldProps<T extends { [key: string]: any }> = {
  formControl: UseFormReturn<T, any, undefined>
  fieldName: Path<T>
  label: string
  required?: boolean
  variant?: FormControlTypeMap['props']['variant']
  infoText?: string
  disabled?: boolean
}

const CustomDatePicker = <T extends { [key: string]: any }>(
  props: CustomTextFieldProps<T>
) => {
  const {
    formControl,
    fieldName,
    label,
    required = false,
    variant,
    infoText,
    disabled,
  } = props

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormControl
        error={!!formControl.formState.errors[fieldName]?.message}
        variant={variant}
      >
        <DatePicker
          {...formControl.register(fieldName, { required: true })}
          onChange={(val) => {
            const newValue = String(val) as T[Path<T>]
            formControl.setValue(fieldName, newValue)
            formControl.trigger(fieldName)
          }}
          format="DD/MM/YYYY"
          slotProps={{
            textField: {
              size: 'small',
              error: !!formControl.formState.errors[fieldName],
              required: required,
              variant: variant,
            },
          }}
          label={label}
          disabled={disabled}
        />
        <FormHelperText variant="filled">
          <>
            {!!infoText ? <InfoTooltipWithText message={infoText} /> : null}
            {formControl.formState.errors[fieldName]?.message
              ? String(formControl.formState.errors[fieldName]?.message)
              : null}
          </>
        </FormHelperText>
      </FormControl>
    </LocalizationProvider>
  )
}

export default CustomDatePicker
