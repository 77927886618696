import { Fragment, useState, useEffect, useRef } from 'react'
import QrScanner from 'qr-scanner'
import { Button, Dialog, Box } from '@mui/material'

function QRReader({ open, onClose, onScan }) {
  const ref = useRef(null)
  const refQrResult = useRef(null)
  const [code, setCode] = useState('')
  const [scanner, setScanner] = useState({})

  const setResult = (label, result) => {
    console.log(result.data)
    setCode(result.data)
    label.textContent = result.data
    label.style.color = 'teal'
    clearTimeout(label.highlightTimeout)
    label.highlightTimeout = setTimeout(
      () => (label.style.color = 'inherit'),
      100
    )
  }

  const initialize = async (el2, camQrResult) => {
    const qrScanner = new QrScanner(
      el2,
      (result) => setResult(camQrResult, result),
      {
        onDecodeError: (error) => {
          camQrResult.textContent = error
          camQrResult.style.color = 'inherit'
        },
        highlightScanRegion: true,
        highlightCodeOutline: true,
      }
    )
    console.log('qrScanner', qrScanner)
    setScanner(qrScanner)
    qrScanner.start()
  }

  const toggleStop = () => {
    // setCode('result')
    toggleCanvas()
    scanner.stop()
    hiddenVideoContent()
  }

  const toggleCanvas = () => {
    const el = document.getElementById('show-scan-region')
    console.log('el', el.parentNode)
    const label = el.parentNode
    label.parentNode.insertBefore(scanner.$canvas, label.nextSibling)
    scanner.$canvas.style.display = 'block'
    scanner.$canvas.style.width = '600px'
    scanner.$canvas.style.height = '450px'
  }

  const hiddenVideoContent = () => {
    const elVideoContent = document.getElementById('videoContainer')
    elVideoContent.style.display = 'none'
  }

  const showVideoContent = () => {
    const elVideoContent = document.getElementById('videoContainer')
    console.log('after-showVideoContent', elVideoContent.style.display)
    elVideoContent.style.display = 'block'
    console.log('before-showVideoContent', elVideoContent.style.display)
  }

  const submit = () => {
    onScan(code)
    scanner.destroy()
    onClose()
  }

  useEffect(() => {
    // console.log("useEffect");
    if (open) {
      setTimeout(() => {
        const el2 = ref.current
        const camQrResult = refQrResult.current
        console.log('el2', el2)
        initialize(el2, camQrResult)
      }, 1000)
      console.log('with out setTimeout')
    }
  }, [open])
  useEffect(() => {
    if (code) {
      toggleStop()
    }
  }, [code])
  // console.log('QrReader', { scanner, code })

  return (
    <Dialog open={open} id="dialog">
      <div id="videoContainer" style={{ display: 'block' }}>
        <video style={{ width: '100%' }} ref={ref} id="qr-video"></video>
        <div id="show-scan-region"></div>
      </div>
      <Box sx={{ mt: 4 }} style={{ color: '#11002b' }}>
        <span>Detected QR code: </span>
        <span ref={refQrResult} id="cam-qr-result">
          None
        </span>
      </Box>
      <Button
        onClick={submit}
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 4 }}
      >
        Done
      </Button>
    </Dialog>
  )
}
export default QRReader
