type SeoConfig = {
  title?: string
  meta?: React.DetailedHTMLProps<
    React.MetaHTMLAttributes<HTMLMetaElement>,
    HTMLMetaElement
  >[]
  children?: React.ReactNode
}

export const seoConfig: SeoConfig = {
  title: process.env.REACT_APP_TITLE || 'Default Title',
  meta: [
    // HTML Meta Tags
    {
      name: 'description',
      content: process.env.REACT_APP_TITLE || 'Default Title',
    },
    // Google / Search Engine Tags
    /**
     * ! according to react-helmet issue
     * ! https://github.com/staylor/react-helmet-async/issues/111
     * ! itemProp key is being ignored
     */
    <meta
      itemProp="name"
      content={process.env.REACT_APP_TITLE || 'Default Title'}
    />,
    <meta
      itemProp="description"
      content={process.env.REACT_APP_DESCRIPTION || 'Default Description'}
    />,
    <meta itemProp="image" content={window.location.href + 'logo512.png'} />,
    // Facebook meta tags
    { property: 'og:url', content: window.location.href },
    { property: 'og:type', content: 'website' },
    {
      property: 'og:title',
      content: process.env.REACT_APP_TITLE || 'Default Title',
    },
    {
      property: 'og:description',
      content: process.env.REACT_APP_DESCRIPTION || 'Default Description',
    },
    {
      property: 'og:image',
      content: window.location.href + 'logo512.png',
    },
    // Twitter meta tags
    { name: 'twitter:card', content: 'summary_large_image' },
    {
      name: 'twitter:title',
      content: process.env.REACT_APP_TITLE || 'Default Title',
    },
    {
      name: 'twitter:description',
      content: process.env.REACT_APP_DESCRIPTION || 'Default Description',
    },
    {
      name: 'twitter:image',
      content: window.location.href + 'logo512.png',
    },
  ],
}
