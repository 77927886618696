import CloseIcon from '@mui/icons-material/Close'
import { Button, Modal, Paper, Typography } from '@mui/material'
import { ModalContext } from 'context/ModalContext'
import ErrorIcon from '@mui/icons-material/Error'

import React, { useContext } from 'react'

type Props = {}

const ConfirmModal: React.FC<Props> = () => {
  const { isModalOpen, setIsModalOpen, variant, message, onConfirm } =
    useContext(ModalContext)

  const handleClose = () => {
    setIsModalOpen(false)
  }

  return (
    <Modal
      open={isModalOpen}
      onClose={() => {}}
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Paper sx={{ width: '400px', height: '240px', p: '24px' }}>
        <div className="h-full flex flex-col items-center justify-between relative">
          {message?.icon ? (
            <img className="h-12" src={message?.icon} />
          ) : (
            <ErrorIcon sx={{ color: '#3BBBE6' }} fontSize="large" />
          )}
          {variant !== 'information' && (
            <CloseIcon
              sx={{ position: 'absolute', top: 0, right: 0, color: '#667085' }}
              onClick={handleClose}
            />
          )}
          <div className="flex flex-col items-center">
            <Typography variant="body1" sx={{ fontWeight: 600 }}>
              {message?.tittle}
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontWeight: 400, color: '#727272' }}
            >
              {message?.description}
            </Typography>
          </div>
          <div className="flex w-full space-x-2">
            {variant === 'confirm' && (
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={handleClose}
                  sx={{ borderColor: '#D0D5DD' }}
                >
                  <Typography sx={{ color: '#D92D20' }}>Cancel</Typography>
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    // console.log(onConfirm)
                    onConfirm()
                    handleClose()
                  }}
                  fullWidth
                >
                  Yes, clear all
                </Button>
              </>
            )}
            {variant === 'information' && (
              <>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleClose}
                  sx={{ bgcolor: '#3BBBE6' }}
                >
                  <Typography>Close</Typography>
                </Button>
              </>
            )}
          </div>
        </div>
      </Paper>
    </Modal>
  )
}

export default ConfirmModal
