import { Typography } from '@mui/material'
import CustomDivider from 'components/CustomDivider/CustomDivider'
import { FormRow } from 'components/FormBuilder/FormBuilder'

export type PreRegisForm = {
  firstName?: string
  lastName?: string
  email?: string
  phoneNumber?: string
  gender?: string
  age?: string
  dob?: string
  countryOfResidence?: string
  nationality?: string
  minerCount?: string
  emergencyContactName?: string
  emergencyContactPhoneNumber?: string
  allergy?: string
  howWillYouGetToWF?: string
  howManyPeopleAreTravelingInYourGroup?: string
  countryOfDeparture?: string
  cityOfDeparture?: string
}

export const createFromInitialValues = () => {
  const initialValue: PreRegisForm = {}
  formRows.forEach((row) => {
    if (row.type !== 'input') return
    initialValue[row.logicConfig.fieldName] = ''
  })

  return initialValue
}

export const formRows: FormRow<PreRegisForm>[] = [
  {
    type: 'component',
    render: () => {
      return <CustomDivider tittle="General Information" />
    },
  },
  {
    type: 'input',
    uiSettings: {
      label: 'First Name',
      required: true,
    },
    logicConfig: {
      fieldName: 'firstName',
      apiFieldName: 'FIRST_NAME',
      fieldVariant: 'text',
      validationType: 'default',
    },
  },
  {
    type: 'input',
    uiSettings: {
      label: 'Last Name',
      required: true,
    },
    logicConfig: {
      fieldName: 'lastName',
      apiFieldName: 'LAST_NAME',
      fieldVariant: 'text',
      validationType: 'default',
    },
  },
  {
    type: 'input',
    uiSettings: {
      label: 'E-mail',
      required: true,
    },
    logicConfig: {
      fieldName: 'email',
      apiFieldName: 'EMAIL',
      fieldVariant: 'text',
      validationType: 'email',
    },
  },
  {
    type: 'input',
    uiSettings: {
      label: 'Phone Number',
      required: true,
    },
    logicConfig: {
      fieldName: 'phoneNumber',
      apiFieldName: 'PHONE_NUMBER',
      fieldVariant: 'phoneNumber',
    },
  },
  {
    type: 'input',
    uiSettings: {
      label: 'Gender',
      required: true,
    },
    logicConfig: {
      fieldName: 'gender',
      apiFieldName: 'GENDER',
      fieldVariant: 'dropdown',
      dropdownOptions: ['Male', 'Female', 'Other'],
    },
  },
  {
    type: 'input',
    uiSettings: {
      label: 'Age',
      required: true,
    },
    logicConfig: {
      fieldName: 'age',
      apiFieldName: 'AGE',
      fieldVariant: 'dropdownWithPreset',
      optionPreset: 'age',
    },
  },
  {
    type: 'input',
    uiSettings: {
      label: 'Date of Birth',
      required: true,
    },
    logicConfig: {
      fieldName: 'dob',
      apiFieldName: 'DOB',
      fieldVariant: 'datePicker',
    },
  },
  {
    type: 'input',
    uiSettings: {
      label: 'Country of Residence',
      required: true,
    },
    logicConfig: {
      fieldName: 'countryOfResidence',
      apiFieldName: 'DATA_003',
      fieldVariant: 'dropdownWithPreset',
      optionPreset: 'countries',
    },
  },
  {
    type: 'input',
    uiSettings: {
      label: 'Nationality',
      required: true,
    },
    logicConfig: {
      fieldName: 'nationality',
      apiFieldName: 'NATIONALITY',
      fieldVariant: 'dropdownWithPreset',
      optionPreset: 'nationalities',
    },
  },
  {
    type: 'input',
    uiSettings: {
      label: 'How many children under 13 will be attending the event?',
      required: true,
    },
    logicConfig: {
      fieldName: 'minerCount',
      apiFieldName: 'DATA_004',
      fieldVariant: 'dropdown',
      dropdownOptions: ['0', '1', '2', '3', '4', '5 or more'],
    },
  },
  {
    type: 'component',
    render: () => {
      return <CustomDivider tittle="Emergency Information" />
    },
  },
  {
    type: 'input',
    uiSettings: {
      label: 'Emergency contact person name',
      required: true,
    },
    logicConfig: {
      fieldName: 'emergencyContactName',
      apiFieldName: 'DATA_005',
      fieldVariant: 'text',
    },
  },
  {
    type: 'input',
    uiSettings: {
      label: 'Emergency contact person phone number',
      required: true,
    },
    logicConfig: {
      fieldName: 'emergencyContactPhoneNumber',
      apiFieldName: 'DATA_006',
      fieldVariant: 'phoneNumber',
    },
  },
  {
    type: 'input',
    uiSettings: {
      label: 'Allergy information',
      required: true,
    },
    logicConfig: {
      fieldName: 'allergy',
      apiFieldName: 'DATA_007',
      fieldVariant: 'text',
    },
  },
  {
    type: 'component',
    render: () => {
      return <CustomDivider tittle="Carbon offset information" />
    },
  },
  {
    type: 'input',
    uiSettings: {
      label: 'How will you get to Wonderfruit?',
      required: true,
    },
    logicConfig: {
      fieldName: 'howWillYouGetToWF',
      apiFieldName: 'DATA_008',
      fieldVariant: 'dropdown',
      dropdownOptions: ['Taxi', 'Private Car', 'Bus', 'Train'],
    },
  },
  {
    type: 'input',
    uiSettings: {
      label: 'How many people are traveling in your group?',
      required: true,
    },
    logicConfig: {
      fieldName: 'howManyPeopleAreTravelingInYourGroup',
      apiFieldName: 'DATA_009',
      fieldVariant: 'dropdown',
      dropdownOptions: ['1', '2', '3', 'More than 3'],
    },
  },
  {
    type: 'input',
    uiSettings: {
      label: 'What is your country of departure?',
      required: true,
    },
    logicConfig: {
      fieldName: 'countryOfDeparture',
      apiFieldName: 'DATA_010',
      fieldVariant: 'dropdownWithPreset',
      optionPreset: 'countries',
    },
  },
  {
    type: 'input',
    uiSettings: {
      label: 'City of departure in Thailand',
      required: true,
    },
    logicConfig: {
      fieldName: 'cityOfDeparture',
      apiFieldName: 'DATA_011',
      fieldVariant: 'dropdownWithPreset',
      optionPreset: 'eng_province',
    },
  },
]

export const defaultValues: PreRegisForm = {}
