import { Breakpoints, createTheme } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    overline1: React.CSSProperties
    overline2: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    overline1?: React.CSSProperties
    overline2?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    overline1: true
    overline2: true
    overline: false
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#000',
    },
    secondary: {
      main: '#3BBBE6',
    },
    error: {
      main: '#AD1B1B',
    },
    info: {
      main: '#727272',
    },
    text: {
      primary: '#000',
      secondary: '#FFF',
    },
  },
  typography: {
    fontFamily: ['Acumin Pro', 'Emilio'].join(','),
  },
  spacing: 10,
  components: {
    MuiTypography: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          color: theme.palette.text.primary,
          whiteSpace: 'pre-wrap',
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ ownerState, theme }) => {
          return {
            ...(ownerState.customBorder || {
              border: '1px solid',
              borderColor: '#FFF',
              borderRadius: '20px',
              background: '#fff',
              backdropFilter: 'blur(39.5px)',
            }),
          }
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          fontSize: '0.875rem',
          '&:before': {
            borderBottom: `1px solid ${theme.palette.primary.main} !important`,
          },
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          fontSize: '0.875rem',
          color: theme.palette.text.primary,
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          textTransform: 'none',
          fontWeight: 600,
          fontSize: '16px',
          ':disabled': {
            backgroundColor: '#FFF',
          },
          borderRadius: '20px',
        }),
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          color: theme.palette.text.primary,
        }),
      },
    },
    MuiList: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          color: theme.palette.primary.main,
        }),
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          fontWeight: '600',
          fontSize: '32px',
          '& .MuiFormLabel-asterisk': {
            color: 'red',
          },
        }),
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          color: theme.palette.text.primary,
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: ({ ownerState, theme }) => {
          return {
            color: ownerState.checked
              ? theme.palette.primary.main
              : theme.palette.text.primary,
            fontWeight: '500',
            fontSize: '24px',
            lineHeight: '32px',
          }
        },
      },
    },
  },
})

theme.typography.h1 = {
  fontSize: '48px',
  fontWeight: '500',
  lineHeight: '60px',
  [theme.breakpoints.up('sm')]: {},
}

theme.typography.h2 = {
  fontSize: '39px',
  fontWeight: '500',
  lineHeight: '48px',
}

theme.typography.h3 = {
  fontSize: '31px',
  fontWeight: '500',
  lineHeight: '40px',
}

theme.typography.h4 = {
  fontSize: '25px',
  fontWeight: '500',
  lineHeight: '32px',
}

theme.typography.h5 = {
  fontSize: '20px',
  fontWeight: '500',
  lineHeight: '24px',
}

theme.typography.h6 = {
  fontSize: '16px',
  fontWeight: '500',
  lineHeight: '21px',
}

theme.typography.subtitle1 = {
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '21px',
  [theme.breakpoints.up('sm')]: {
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '23px',
  },
}

theme.typography.subtitle2 = {
  fontSize: '16px',
  fontWeight: '700',
  lineHeight: '21px',
  [theme.breakpoints.up('sm')]: {
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '23px',
  },
}

theme.typography.body1 = {
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '21px',
}

theme.typography.body2 = {
  fontSize: '16px',
  fontWeight: '700',
  lineHeight: '21px',
}

theme.typography.overline1 = {
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '18px',
}

theme.typography.overline2 = {
  fontSize: '14px',
  fontWeight: '700',
  lineHeight: '18px',
}

theme.typography.caption = {
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '18px',
}

export default theme
