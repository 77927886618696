import React from 'react'
import { Box, SxProps, Theme } from '@mui/material'

type props = {
  image?: string
  sx?: SxProps<Theme>
  tailwindClassName?: string
}

const LogoBrand: React.FC<props> = ({ image, sx, tailwindClassName }) => {
  return (
    <Box
      sx={{
        pb: 0,
        pt: 5,
        mb: 0,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        ...sx,
      }}
    >
      <img
        className={'max-w-[440px]' + ' ' + tailwindClassName}
        src={image}
        width="100%"
      />
    </Box>
  )
}

export default LogoBrand
