import CameraAltIcon from '@mui/icons-material/CameraAlt'
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import ticketMockupImg from 'assets/images/ticketMockup.png'
import QRReader from 'components/QrReader/QrReader'
import { formatEntrantCode } from 'components/TextFieldMask/TextFieldMask'
import UploadQrCodeButton from 'components/UploadQrCodeButton/UploadQrCodeButton'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { checkCode } from 'service/PreregisService'

const OrderVerification: React.FC = () => {
  const [orderNumber, setOrderNumber] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()

  const setFormattedOrderNumber = (value: string) => {
    const formattedValue = formatEntrantCode(value)
    setOrderNumber(formattedValue)
  }

  const onSubmit = async () => {
    setIsLoading(true)
    const result = await checkCode(orderNumber)
    if (result.errorMessage !== '') {
      setErrorText(result.errorMessage || '')
    }
    if (result?.statusCode === 110) {
      navigate(`/thankyou/${orderNumber}`)
    }
    if (result?.statusCode === 0) {
      navigate(`/information/${orderNumber}`)
    }
    setIsLoading(false)
  }

  return (
    <Stack direction={'column'} spacing={2}>
      <Box>
        <Typography
          variant="h5"
          align="center"
          sx={{ fontSize: '22px', fontWeight: 700, mb: 3 }}
        >
          Pre-register to save time at the gate
        </Typography>
        <div className="text-center">
          <Typography variant="overline1">
            All tickets to Wonderfruit must be registered, and pre-registering
            will help save time at the gate. Pre-registering is also when you
            can change the name on a ticket if you bought multiple. Confirm your
            details by using the 9-digit Entrant Code on your ticket.
          </Typography>
        </div>
      </Box>
      <img className="pt-2" src={ticketMockupImg} />
      <Box
        sx={{
          '& .MuiTypography-root': { color: 'error.main', fontStyle: 'italic' },
          'li::marker': { color: 'error.main' },
        }}
      >
        <Typography variant="overline1" sx={{ mb: 1 }}>
          Important to note:
        </Typography>
        <div>
          <ul className="list-disc pl-[40px]">
            <li>
              <Typography variant="overline1">
                {
                  'You will only have one chance to complete the form so please\nmake sure all your info is correct as '
                }
                <b>no changes or corrections</b>
                {' can be made once submitted.'}
              </Typography>
            </li>
            <li>
              <Typography variant="overline1">
                You can only register an e-mail address once.
              </Typography>
            </li>
            <li>
              <Typography variant="overline1">
                {'You will need a photo ID at the gate to gain entry; use the '}
                <b>FULL NAME as shown on that ID</b>
                {' in your pre-registration.'}
              </Typography>
            </li>
            <li>
              <Typography variant="overline1">
                {'Use an active '}
                <b>email address</b>
                {
                  ' as you will be asked to verify your email to receive surprise updates.'
                }
              </Typography>
            </li>
            <li>
              <Typography variant="overline1">
                Accommodation pre-registration will follow at the end of this
                form.
              </Typography>
            </li>
          </ul>
        </div>
      </Box>
      <Box>
        <Typography variant="body1" sx={{ fontWeight: 700 }}>
          Please enter your Entrant Code:
        </Typography>
      </Box>
      <div className="flex flex-col space-y-2">
        <TextField
          value={orderNumber}
          onChange={(e) => {
            setFormattedOrderNumber(e.target.value)
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onSubmit()
            }
          }}
          label="Entrant Code"
          placeholder="Enter the 9-digit Entrant Code"
          variant="standard"
          color="primary"
          error={!!errorText}
          helperText={errorText}
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle cam"
                  onClick={() => setOpen((prev) => !prev)}
                  edge="end"
                >
                  <CameraAltIcon style={{ color: '#000' }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <UploadQrCodeButton
          isDisabled={!!orderNumber}
          setOrderNumber={setOrderNumber}
        />
      </div>
      <div className="flex justify-center pt-4">
        <Button
          variant="contained"
          onClick={onSubmit}
          disabled={orderNumber.replace(/-/g, '').length !== 9 || isLoading}
          fullWidth
          sx={
            {
              // "&.Mui-disabled": {
              //   backgroundColor: "rgba(255, 255, 255, 0.2)",
              // },
            }
          }
        >
          {isLoading ? (
            <CircularProgress size={24} sx={{ color: '#3bbbe6' }} />
          ) : (
            'Submit'
          )}
        </Button>
        <QRReader
          open={open}
          onClose={() => setOpen((prev) => !prev)}
          onScan={(value: string) => {
            setOrderNumber(value)
          }}
        />
      </div>
    </Stack>
  )
}

export default OrderVerification
