import React from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box, Typography } from '@mui/material'

type Props = {
  message: string
}

const InfoTooltipWithText: React.FC<Props> = ({ message }) => {
  return (
    <Box sx={{ color: 'info.main' }}>
      <div className="flex items-center space-x-1">
        <InfoOutlinedIcon sx={{ fontSize: '16px' }} fontSize="small" />
        <Typography sx={{ fontSize: '12px' }}>{message}</Typography>
      </div>
    </Box>
  )
}

export default InfoTooltipWithText
