import { AlertColor } from '@mui/material'
import { ReactNode, createContext, useState } from 'react'

export type AlertContextType = {
  alertData: {
    message: string
    alertType: AlertColor
  }
  setAlert: React.Dispatch<
    React.SetStateAction<{
      message: string
      alertType: AlertColor
    }>
  >
}

export const AlertContext = createContext<AlertContextType>({
  alertData: {
    message: '',
    alertType: 'error',
  },
  setAlert: () => {},
})

type Props = {
  children: ReactNode
}

export const AlertContextProvider: React.FC<Props> = ({ children }) => {
  const [alertData, setAlert] = useState<{
    message: string
    alertType: AlertColor
  }>({
    message: '',
    alertType: 'error',
  })

  return (
    <AlertContext.Provider value={{ alertData, setAlert }}>
      {children}
    </AlertContext.Provider>
  )
}
