import React, { Fragment } from 'react'
import { Box, Typography } from '@mui/material'

type props = {
  title?: React.ReactNode
  subTitle?: React.ReactNode
  sx?: object
}

const ContentTextTitle: React.FC<props> = ({ title, subTitle, sx }) => {
  return (
    <Box sx={{ pb: 4, textAlign: 'center' }}>
      <Typography
        variant="h4"
        fontFamily={'Emilio'}
        sx={{ ...sx, whiteSpace: 'pre-wrap', color: 'text.primary' }}
      >
        {title}
      </Typography>
      <Typography
        fontFamily={'Emilio'}
        sx={{ fontSize: { xs: '16px', sm: '20px' }, pt: 1 }}
      >
        {subTitle}
      </Typography>
    </Box>
  )
}

ContentTextTitle.defaultProps = {
  title: (
    <Fragment>
      Ticketmelon Thailand <br /> Wristband Pre-registration
    </Fragment>
  ),
}

export default ContentTextTitle
