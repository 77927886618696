import { useEffect, useRef } from 'react'
import * as QRCode from 'qrcode'

interface QRCodeGeneratorProps {
  data: string
  options?: QRCode.QRCodeRenderersOptions
  onRefresh?(): object
}

const DEFAULT_WIDTH = 300

export function QRCodeGenerator({ data, options }: QRCodeGeneratorProps) {
  const width = options?.width || DEFAULT_WIDTH
  const ref = useRef<HTMLCanvasElement | null>(null)

  const generateQR = (data: string) => {
    QRCode.toCanvas(
      ref.current,
      data,
      {
        errorCorrectionLevel: 'L',
        width,
        ...options,
      },
      (error) => {
        if (error) {
          console.error(error)
        }
      }
    )
  }

  useEffect(() => {
    generateQR(data)
  }, [])

  return (
    <>
      <canvas ref={ref} />
    </>
  )
}
