import { ThemeProvider } from '@emotion/react'
import React, { ReactNode } from 'react'
import theme from 'theme'
import { LoadingContextProvider } from './LoadingContext'
import { AlertContextProvider } from './AlertContext'
import { ModalContextProvider } from './ModalContext'

type Props = {
  children: ReactNode
}

const RootProvider: React.FC<Props> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <AlertContextProvider>
        <AlertContextProvider>
          <ModalContextProvider>
            <LoadingContextProvider>{children}</LoadingContextProvider>
          </ModalContextProvider>
        </AlertContextProvider>
      </AlertContextProvider>
    </ThemeProvider>
  )
}

export default RootProvider
