import { BgLayout, ContainerLayout, PaperLayout } from 'Layout'
import { seoConfig } from 'config/seoConfig'
import RootProvider from 'context/RootProvider'
import AccommodationRegisForm from 'pages/AccomRegisForm/AccomRegisForm'
import NotFound from 'pages/NotFound/NotFound'
import OrderVerification from 'pages/OrderVerification/OrderVerification'
import RegistrationForm from 'pages/RegistrationForm/RegistrationForm'
import TermAndCondition from 'pages/TermAndCondition/TermAndCondition'
import Thankyou from 'pages/Thankyou/Thankyou'
import React from 'react'
import { Helmet } from 'react-helmet'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { useAnalytics } from './hook/use-analytics'

const App: React.FC = () => {
  useAnalytics({ containerId: 'GTM-WVJMZWH7' })
  return (
    <RootProvider>
      <Helmet {...seoConfig} htmlAttributes={{ lang: 'en' }} />
      <div>
        <BrowserRouter>
          <Routes>
            {/* scrollTo top is in Layout.tsx */}
            <Route element={<BgLayout />}>
              <Route path="/" element={<ContainerLayout />}>
                <Route path="thankyou/:qrCode" element={<Thankyou />} />
                <Route element={<PaperLayout />}>
                  <Route index element={<OrderVerification />} />
                  <Route
                    path="information/:qrCode"
                    element={<RegistrationForm />}
                  />
                  <Route
                    path="accommodation/:qrCode"
                    element={<AccommodationRegisForm />}
                  />
                  <Route
                    path="terms-and-conditions"
                    element={<TermAndCondition />}
                  />
                </Route>
              </Route>
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    </RootProvider>
  )
}

export default App

//                             _
//                          _ooOoo_
//                         o8888888o
//                         88" . "88
//                         (| -_- |)
//                         O\  =  /O
//                      ____/`---'\____
//                    .'  \\|     |//  `.
//                   /  \\|||  :  |||//  \
//                  /  _||||| -:- |||||_  \
//                  |   | \\\  -  /'| |   |
//                  | \_|  `\`---'//  |_/ |
//                  \  .-\__ `-. -'__/-.  /
//                ___`. .'  /--.--\  `. .'___
//             ."" '<  `.___\_<|>_/___.' _> \"".
//            | | :  `- \`. ;`. _/; .'/ /  .' ; |
//            \  \ `-.   \_\_`. _.'_/_/  -' _.' /
//  ===========`-.`___`-.__\ \___  /__.-'_.'_.-'================
//                          `=--=-'
