import { CircularProgress, Modal } from '@mui/material'

export const Loading: React.FC<{
  isLoading: boolean
}> = ({ isLoading }) => {
  return (
    <Modal open={isLoading} onClose={() => {}}>
      <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
        <CircularProgress size={24} sx={{ color: '#3bbbe6' }} />
      </div>
    </Modal>
  )
}
