import { CheckBoxWithLabel } from 'components/CheckBoxWithLabel/CheckBoxWithLabel'

type props = {
  isAcceptPolicy: boolean
  setIsAcceptPolicy: React.Dispatch<React.SetStateAction<boolean>>
  disabled?: boolean
}

const FormAcceptCheckBox: React.FC<props> = ({
  isAcceptPolicy,
  setIsAcceptPolicy,
  disabled,
}) => {
  return (
    <CheckBoxWithLabel
      label={
        <div>
          {`I accept ${process.env.REACT_APP_EVENT_NAME} `}
          <a
            className="text-primary underline"
            href={process.env.REACT_APP_PRIVACY_AND_POLICY}
            target="_blank"
          >
            {'Privacy Policy'}
          </a>
          {' and '}
          <a
            className="text-primary underline"
            href={process.env.REACT_APP_TERMS_AND_CONDITION}
            target="_blank"
          >
            {'Terms and Conditions'}
          </a>
        </div>
      }
      disabled={disabled}
      isCheck={isAcceptPolicy}
      setIsCheck={setIsAcceptPolicy}
    />
  )
}

export default FormAcceptCheckBox
