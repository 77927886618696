import { Stack, TextFieldVariants, Typography } from '@mui/material'
import CustomSelect from 'components/CustomSelect/CustomSelect'
import CustomTextField from 'components/CustomTextField/CustomTextField'
import { FieldValues, Path, UseFormReturn } from 'react-hook-form'
import * as yup from 'yup'

import ageSelectOptions from 'data/age.json'
import countriesSelectOptions from 'data/countries.json'
import nationalitiesSelectOptions from 'data/nationalities.json'
import occupationsSelectOptions from 'data/occupations.json'
import provinceSelectOptions from 'data/province.json'
import CustomDatePicker from 'components/CustomDatePicker/CustomDatePicker'
import MuiPhoneNumber from 'material-ui-phone-number'

type CommonField<T extends FieldValues> = {
  uiSettings: UIAccessibilitySettings
  logicConfig: FieldAPILogicConfig<T>
}

type UIAccessibilitySettings = {
  label: string
  required?: boolean
  errorText?: string
  infoText?: string
  // i case of using custom component, hide input but will still be validated and formatted
  isHidden?: boolean
}

type FieldAPILogicConfig<T extends FieldValues> = {
  fieldName: Path<T>
  apiFieldName: ApiFieldName
} & (
  | TextInputField<T>
  | DatePickerField<T>
  | DropdownField<T>
  | DropdownWithPresetField<T>
  | PhoneNumberWithCountryCodeField<T>
)

type ApiFieldName =
  | 'FIRST_NAME'
  | 'EMAIL'
  | 'GENDER'
  | 'PHONE_NUMBER'
  | 'LAST_NAME'
  | 'NATIONALITY'
  | 'DOB'
  | 'AGE'
  | 'DATA_003'
  | 'DATA_004'
  | 'DATA_005'
  | 'DATA_006'
  | 'DATA_007'
  | 'DATA_008'
  | 'DATA_009'
  | 'DATA_010'
  | 'DATA_011'
  | 'DATA_012'
  | 'DATA_013'
  | string

type TextInputField<T> = {
  fieldVariant: 'text'
  validationType?:
    | 'default'
    | 'engChrOnly'
    | 'numberOnly'
    | 'email'
    | 'phoneNumber'
}

type PhoneNumberWithCountryCodeField<T> = {
  fieldVariant: 'phoneNumber'
}

type DatePickerField<T> = {
  fieldVariant: 'datePicker'
}

type DropdownField<T> = {
  fieldVariant: 'dropdown'
  dropdownOptions: string[]
}

type DropdownWithPresetField<T> = {
  fieldVariant: 'dropdownWithPreset'
  optionPreset: SelectOptionPreset
}

type InputFieldConfig<T extends FieldValues> = CommonField<T> & {
  type: 'input'
}

/**
 *
 * if you want to add custom component to form builder
 * PS. incase of render custom input
 * validation function and error message will not be applied along with formatting to request body
 * to properly add validation and format you need to add yup schema and format function manually in RegistrationForm.tsx
 *
 * */

type ComponentField = {
  type: 'component'
  render: () => React.ReactNode
}

export type FormRow<T extends FieldValues> =
  | InputFieldConfig<T>
  | ComponentField

export type FormUiSettings = {
  rowInputVariant?: TextFieldVariants
}

type FormBuilderProps<T extends FieldValues> = {
  formRows: FormRow<T>[]
  formControl: UseFormReturn<T, any, undefined>
  formUiSettings?: FormUiSettings
  isDisabled?: boolean
}

const FormBuilder = <T extends FieldValues>(props: FormBuilderProps<T>) => {
  const { formRows, formControl, formUiSettings, isDisabled } = props

  return (
    <Stack spacing={1.5}>
      {formRows.map((row, index) => {
        if (row.type === 'component')
          return <div key={index}>{row.render()}</div>

        const { logicConfig, uiSettings } = row

        if (uiSettings.isHidden) return

        if (
          !!!logicConfig?.fieldVariant ||
          logicConfig?.fieldVariant === 'text'
        )
          return (
            <CustomTextField
              key={index}
              formControl={formControl}
              fieldName={logicConfig.fieldName}
              label={uiSettings.label}
              required={uiSettings.required}
              variant={formUiSettings?.rowInputVariant}
              infoText={uiSettings?.infoText}
              disabled={isDisabled}
            />
          )

        if (logicConfig?.fieldVariant === 'phoneNumber') {
          const { fieldName } = logicConfig
          const { label, infoText, required } = uiSettings

          return (
            // TODO : Make our own version of phone number input
            <MuiPhoneNumber
              size="small"
              variant={formUiSettings?.rowInputVariant}
              label={label}
              defaultCountry={'th'}
              onChange={(e) => {
                formControl.setValue(fieldName, e as any)
                formControl.trigger(fieldName)
              }}
              error={!!formControl.formState.errors[fieldName]}
              sx={{
                '& .MuiFormHelperText-root': {
                  '& .MuiTypography-root': {
                    display: 'none',
                  },
                },
                '& .MuiFormHelperText-root.Mui-error': {
                  '& .MuiTypography-root': {
                    display: 'inline',
                  },
                },
              }}
              helperText={
                <Typography variant="caption" color="error.main">
                  Phone number format is not correct
                </Typography>
              }
              required={required}
              disabled={isDisabled}
            />
          )
        }

        if (
          logicConfig?.fieldVariant === 'dropdown' ||
          logicConfig?.fieldVariant === 'dropdownWithPreset'
        ) {
          const dropdownOptions =
            logicConfig?.fieldVariant === 'dropdown'
              ? logicConfig?.dropdownOptions
              : selectOptionPreset[logicConfig.optionPreset]

          return (
            <CustomSelect
              key={index}
              formControl={formControl}
              fieldName={logicConfig.fieldName}
              label={uiSettings.label}
              required={uiSettings.required}
              selectOptions={dropdownOptions}
              variant={formUiSettings?.rowInputVariant}
              infoText={uiSettings?.infoText}
              disabled={isDisabled}
            />
          )
        }

        if (logicConfig?.fieldVariant === 'datePicker') {
          return (
            <CustomDatePicker
              key={index}
              formControl={formControl}
              fieldName={logicConfig.fieldName}
              label={uiSettings.label}
              required={uiSettings.required}
              variant={formUiSettings?.rowInputVariant}
              infoText={uiSettings?.infoText}
              disabled={isDisabled}
            />
          )
        }

        return
      })}
    </Stack>
  )
}

type SelectOptionPreset =
  | 'age'
  | 'countries'
  | 'nationalities'
  | 'occupations'
  | 'thai_province'
  | 'eng_province'
  | 'number'

const selectOptionPreset: Record<SelectOptionPreset, string[]> = {
  age: ageSelectOptions,
  countries: countriesSelectOptions,
  nationalities: nationalitiesSelectOptions,
  occupations: occupationsSelectOptions,
  thai_province: provinceSelectOptions.map((province) => province.name_th),
  eng_province: provinceSelectOptions.map((province) => province.name_en),
  number: Array.from(Array(100).keys()).map((number) => number.toString()),
}

const getTextValidator = (
  validationType?: string,
  required?: boolean,
  errorText?: string
) => {
  let validator: yup.StringSchema | yup.NumberSchema = yup.string()

  if (validationType === 'engChrOnly') {
    validator = yup
      .string()
      .matches(
        /^[a-zA-Z ]+$/,
        !!errorText
          ? errorText
          : 'Make sure you fill in your details in English only.'
      )
  }

  if (validationType === 'numberOnly') {
    validator = yup
      .number()
      .typeError(!!errorText ? errorText : 'Must be a number.')
  }

  if (validationType === 'email') {
    validator = yup
      .string()
      .email(!!errorText ? errorText : 'E-mail format is not correct.')
  }

  return required
    ? validator.required(!!errorText ? errorText : 'This field is required.')
    : validator
}

const getNonTextValidator = (required?: boolean, errorText?: string) => {
  let validator = yup.string()

  return required
    ? validator.required(!!errorText ? errorText : 'This field is required.')
    : validator
}

type CreateYupSchemaProps<T extends FieldValues> = {
  formRows: FormRow<T>[]
}

export const createYupSchema = <T extends FieldValues>(
  input: CreateYupSchemaProps<T>
) => {
  const { formRows } = input
  const schema: { [key: string]: any } = {}

  formRows.forEach((row) => {
    if (row.type === 'component') return

    const { uiSettings, logicConfig } = row

    const field = logicConfig.fieldName
    if (logicConfig.fieldVariant === 'text') {
      schema[field] = getTextValidator(
        logicConfig.validationType,
        uiSettings.required,
        uiSettings.errorText
      )
      return
    }

    const { required, errorText } = uiSettings
    schema[field] = getNonTextValidator(required, errorText)
  })

  return yup.object().shape(schema)
}

type FormatFormValuesToRequestBodyProps<T extends FieldValues> = {
  formControl: UseFormReturn<T, any, undefined>
  formRows: FormRow<T>[]
}

export const formatFormValuesToRequestBody = <T extends FieldValues>(
  input: FormatFormValuesToRequestBodyProps<T>
) => {
  const { formControl, formRows } = input

  const body: { [key: string]: any } = {}

  formRows.forEach((row) => {
    if (row.type === 'component') return

    const { fieldName, apiFieldName } = row.logicConfig
    let value = formControl.getValues(fieldName)

    if (typeof value === 'string') {
      value = value.trim()
    }

    body[apiFieldName] = value
  })

  return body
}

export default FormBuilder
