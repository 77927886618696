import CameraAltIcon from '@mui/icons-material/CameraAlt'
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import ticketImage from 'assets/images/ticketImg.png'
import QRReader from 'components/QrReader/QrReader'
import { formatEntrantCode } from 'components/TextFieldMask/TextFieldMask'
import TicketData, { TicketDataRow } from 'components/TicketData/TicketData'
import UploadQrCodeButton from 'components/UploadQrCodeButton/UploadQrCodeButton'
import { AlertContext } from 'context/AlertContext'
import { LoadingContext } from 'context/LoadingContext'
import { ModalContext } from 'context/ModalContext'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { checkAddOn, checkCode, regisAddOn } from 'service/PreregisService'

const AccommodationRegisForm = () => {
  const { isLoading, setIsLoading } = useContext(LoadingContext)
  const [ticketData, setTicketData] = useState<TicketDataRow[]>([])
  const [isAccommodationValid, setIsAccommodationValid] =
    useState<boolean>(false)
  const [accommodationNumber, setAccommodationNumber] = useState('')
  const { qrCode } = useParams()
  const navigate = useNavigate()
  const { setAlert } = useContext(AlertContext)
  const [readerOpen, setReaderOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  )
  const [accommodationData, setAccommodationData] = useState<{
    remain: number
    total: number
  }>({ remain: 0, total: 0 })

  const setFormattedAccommodationNumber = (value: string) => {
    const formattedValue = formatEntrantCode(value)
    setAccommodationNumber(formattedValue)
  }

  useEffect(() => {
    const check = async (code: string) => {
      const response = await checkCode(code)
      const rawData = response?.data

      if (!!response.data.saveData.ADD_ON_CODE) {
        navigate(`/thankyou/${qrCode}`)
        return
      }
      setTicketData([
        {
          tittle: 'Ticket Details',
          value: '',
        },
        {
          tittle: 'Ticket Type:',
          value: rawData?.ticketTypeDetail?.name,
          isNotBreakAll: true,
        },
        {
          tittle: 'E-mail:',
          value: rawData?.saveData?.EMAIL,
        },
        {
          tittle: 'Entrant Code:',
          value: rawData?.dataTicket?.code,
        },
      ])
    }

    if (!!!qrCode) {
      navigate('/')
      return
    }

    check(qrCode)
  }, [])

  useEffect(() => {
    if (!!!accommodationNumber) return
    if (isAccommodationValid) {
      setIsAccommodationValid(false)
    }
  }, [accommodationNumber])

  const validateAccommodationNumber = async () => {
    if (!!!qrCode || !!!accommodationNumber) return
    setIsLoading(true)
    const res = await checkAddOn(qrCode, accommodationNumber, {})
    if (res.statusCode === 0) {
      setIsAccommodationValid(true)
      setAccommodationData({
        remain: res.data?.addOn?.BALANCE_MAX - res.data?.addOn?.BALANCE,
        total: res.data?.addOn?.BALANCE_MAX,
      })
      setErrorMessage(undefined)
    } else {
      if (res.statusCode === 122) {
        setErrorMessage(
          `This accommodation' entrant code has already fully registered for all guests`
        )
      } else {
        setErrorMessage(`This accommodation' entrant code was not found`)
      }
      setIsAccommodationValid(false)
      setAccommodationData({ remain: 0, total: 0 })
    }
    setIsLoading(false)
  }

  const onSubmit = async () => {
    if (!!!qrCode || !!!accommodationNumber) {
      setErrorMessage('Please enter your accommodation number')
      return
    }
    setIsLoading(true)
    const res = await regisAddOn(qrCode, accommodationNumber, {})
    if (res.statusCode === 0) {
      navigate(`/thankyou/${qrCode}`)
    } else {
      setAlert({
        message:
          'Something went wrong with accommodation registration, please try again.',
        alertType: 'error',
      })
    }
    setIsLoading(false)
  }

  return (
    <>
      <Stack direction={'column'} spacing={2}>
        <TicketData
          showImage
          ticketImage={ticketImage}
          tittle="Your ticket details"
          rows={ticketData}
        />
        <Box sx={{ 'li::marker': { color: 'error.main' } }}>
          <Typography variant="overline1" color="error.main">
            Important to note:
          </Typography>
          <div>
            <ul className="list-disc pl-[40px]">
              <li>
                <Typography variant="overline1" color="error.main">
                  {
                    'You will only have one chance to complete the form so please make sure all your info is correct as no changes or corrections can be made once submitted.'
                  }
                </Typography>
              </li>
            </ul>
          </div>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <div className="flex flex-col space-y-4">
                <TextField
                  value={accommodationNumber}
                  onChange={(e) => {
                    setFormattedAccommodationNumber(e.target.value)
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      validateAccommodationNumber()
                    }
                  }}
                  label="Entrant Code"
                  placeholder="Enter the 9-digit Entrant Code"
                  variant="standard"
                  color="primary"
                  size="small"
                  fullWidth
                  helperText={errorMessage}
                  error={!!errorMessage}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle cam"
                          onClick={() => setReaderOpen((prev) => !prev)}
                          edge="end"
                        >
                          <CameraAltIcon style={{ color: '#000' }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <UploadQrCodeButton
                  isDisabled={false}
                  setOrderNumber={setAccommodationNumber}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                variant="contained"
                sx={{
                  bgcolor: '#F0ECE5',
                  color: '#000',
                  ':hover': { bgcolor: '#A7A5A1' },
                  boxShadow: 'none',
                  ':disabled': {
                    bgcolor: '#E1E1E1',
                  },
                }}
                fullWidth
                disabled={accommodationNumber.split('-').join('').length !== 9}
                onClick={validateAccommodationNumber}
              >
                Validate
              </Button>
            </Grid>
            <Grid item xs={12}>
              {isAccommodationValid && (
                <Typography variant="overline1">
                  {'Registrations remaining for this accommodation '}
                  <span style={{ color: 'green' }}>
                    {accommodationData.remain}
                  </span>
                  {' / ' + accommodationData.total}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                disabled={!isAccommodationValid}
                onClick={onSubmit}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Stack>
      <QRReader
        open={readerOpen}
        onClose={() => setReaderOpen((prev) => !prev)}
        onScan={(value: string) => {
          setAccommodationNumber(value)
        }}
      />
    </>
  )
}

export default AccommodationRegisForm
