import { Box, Checkbox, SxProps, Theme, Typography } from '@mui/material'

export const CheckBoxWithLabel: React.FC<{
  label: string | React.ReactNode
  isCheck: boolean
  setIsCheck: React.Dispatch<React.SetStateAction<boolean>>
  disabled?: boolean
  sx?: SxProps<Theme>
}> = ({ label, isCheck, setIsCheck, sx, disabled }) => {
  return (
    <Box className="flex items-start" sx={{ ...sx }}>
      <Checkbox
        checked={isCheck}
        size="small"
        disabled={disabled}
        onChange={(e) => {
          setIsCheck(e.target.checked)
        }}
      />
      <Typography variant="overline1" sx={{ fontStyle: 'italic', pt: 1 }}>
        {label}
      </Typography>
    </Box>
  )
}
