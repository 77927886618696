import axios from 'axios'

type ApiResponse = {
  errorMessage?: string
  data?: any
  statusCode?: number
}

const ACTION_CHECK = 'CHECK'
const ACTION_SAVE = 'SAVE'
const ACTION_ADD_ON_CHECK = 'ADD_ON_CHECK'
const ACTION_ADD_ON_SAVE = 'ADD_ON_SAVE'

const endPoint = `https://api.zapy.io/events/${process.env.REACT_APP_EVENT_ID}/regisform`
const eventId = String(process.env.REACT_APP_EVENT_ID)

const handleStatusCode = (statusCode: number): string => {
  switch (statusCode) {
    case 67:
      return 'Your ticket has already been upgraded to a Full Pass. Please use your Full Pass Entrant Code to proceed.'
    case 92:
      return 'Event not found'
    case 93:
      return 'The 9-digit Entrant Code you entered was not found. Please re-confirm the Entrant Code.'
    case 94:
      return 'The Entrant Code cannot register due to transfer in process or transferred. Please try another available Entrant Code.'
    case 95:
      return `Accommodation code cannot be used for this ticket type`
    case 96:
      return 'This email is already registered. Please try another email.'
    case 110:
      return 'The Entrant Code is already registered. Please try another available Entrant Code.'
    default:
      return 'Something went wrong. Please try another available Entrant Code.'
  }
}

const apiRequest = async (
  action: string,
  code: string,
  body?: { [key: string]: any },
  addOnCode?: string
): Promise<ApiResponse> => {
  if (code.replace(/-/g, '').toUpperCase() === 'ADMINTEST') {
    return {
      data: {
        ticketTypeDetail: {
          name: 'All Day Pass',
        },
        dataTicket: {
          code: 'ADMINTEST',
          EMAIL: 'partnerships@ticketmelon.com',
        },
        saveData: {
          FIRST_NAME: 'Ticket',
          LAST_NAME: 'Melon',
        },
      },
      statusCode: 0,
    }
  }

  if (!endPoint || !eventId) {
    return {
      errorMessage: 'Environment variables are not properly configured.',
    }
  }

  try {
    const response = await axios.post(endPoint, {
      EVENT_ID: eventId,
      CODE: code.replace(/-/g, '') ? code.replace(/-/g, '') : undefined,
      ACTION: action,
      ADD_ON_CODE: addOnCode?.replace(/-/g, ''),
      data_profile: body || {},
    })

    if (response.data.statusCode !== 0) {
      return {
        errorMessage: handleStatusCode(response.data.statusCode),
        data: response.data.err_data,
        statusCode: response.data.statusCode,
      }
    }

    return {
      data: response.data.data,
      statusCode: response.data.statusCode,
    }
  } catch (error) {
    return {
      errorMessage: 'Network error or server not responding.',
      statusCode: -1,
    }
  }
}

export const checkCode = async (code: string): Promise<ApiResponse> => {
  return apiRequest(ACTION_CHECK, code)
}

export const regis = async (
  code: string,
  body: { [key: string]: any }
): Promise<ApiResponse> => {
  return apiRequest(ACTION_SAVE, code, body)
}

export const checkAddOn = async (
  code: string,
  addOnCode: string,
  body: { [key: string]: any }
): Promise<ApiResponse> => {
  return apiRequest(ACTION_ADD_ON_CHECK, code, body, addOnCode)
}

export const regisAddOn = async (
  code: string,
  addOnCode: string,
  body: { [key: string]: any }
): Promise<ApiResponse> => {
  return apiRequest(ACTION_ADD_ON_SAVE, code, body, addOnCode)
}
