import {
  Autocomplete,
  Box,
  FormControlTypeMap,
  Popper,
  TextField,
} from '@mui/material'
import InfoTooltipWithText from 'components/InfoTooltipWithText/InfoTooltipWithText'
import { Path, UseFormReturn } from 'react-hook-form'

type CustomSelectProps<T extends { [key: string]: any }> = {
  formControl: UseFormReturn<T, any, undefined>
  fieldName: Path<T>
  label: string
  selectOptions: string[]
  required?: boolean
  variant?: FormControlTypeMap['props']['variant']
  infoText?: string
  disabled?: boolean
}

const CustomSelect = <T extends { [key: string]: any }>(
  props: CustomSelectProps<T>
) => {
  const {
    formControl,
    fieldName,
    label,
    selectOptions,
    required = false,
    variant,
    infoText,
    disabled,
  } = props

  return (
    <Box
      sx={{
        '& .MuiAutocomplete-listbox': {
          maxHeight: '200px',
          scrollbarWidth: 'none', // Hide the scrollbar for firefox
          '&::-webkit-scrollbar': {
            display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
          },
          '&-ms-overflow-style:': {
            display: 'none', // Hide the scrollbar for IE
          },
        },
        '& .MuiAutocomplete-paper': {
          overflow: 'hidden',
        },
        '& .MuiAutocomplete-noOptions': {
          color: 'black',
        },
      }}
    >
      <Autocomplete
        id={label}
        options={selectOptions}
        disablePortal
        PopperComponent={(props) => (
          <Popper
            modifiers={[
              {
                name: 'flip',
                enabled: false,
              },
            ]}
            {...props}
          />
        )}
        disabled={disabled}
        onChange={(e, value) => {
          formControl.setValue(fieldName, value as any)
          formControl.trigger(fieldName)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...formControl.register(fieldName)}
            required={required}
            size="small"
            error={!!formControl.formState.errors[fieldName]}
            helperText={
              <>
                {!!infoText ? <InfoTooltipWithText message={infoText} /> : null}
                {formControl.formState.errors[fieldName]?.message
                  ? String(formControl.formState.errors[fieldName]?.message)
                  : null}
              </>
            }
            label={label}
            variant={variant}
          />
        )}
      />
    </Box>
  )
}

export default CustomSelect
