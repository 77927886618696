import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Stack, TextField, Typography } from '@mui/material'

import trashIcon from 'assets/icons/trash.svg'
import ticketImage from 'assets/images/ticketImg.png'
import { CheckBoxWithLabel } from 'components/CheckBoxWithLabel/CheckBoxWithLabel'
import FormAcceptCheckBox from 'components/FormAcceptionCheckBox/FormAcceptionCheckBox'
import FormBuilder, {
  createYupSchema,
  formatFormValuesToRequestBody,
} from 'components/FormBuilder/FormBuilder'
import TicketData, { TicketDataRow } from 'components/TicketData/TicketData'
import { AlertContext } from 'context/AlertContext'
import { LoadingContext } from 'context/LoadingContext'
import { ModalContext } from 'context/ModalContext'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import {
  checkAddOn,
  checkCode,
  regis,
  regisAddOn,
} from 'service/PreregisService'
import {
  PreRegisForm,
  createFromInitialValues,
  formRows,
} from './RegistrationFormDecorator'
import CustomDivider from 'components/CustomDivider/CustomDivider'
import { formatEntrantCode } from 'components/TextFieldMask/TextFieldMask'

const RegistrationForm: React.FC<{}> = ({}) => {
  const { isLoading, setIsLoading } = useContext(LoadingContext)
  const [isDisabledForm, setIsDisabledForm] = useState(false)
  const [isAcceptPolicy, setIsAcceptPolicy] = useState(false)
  const [isWantMoreInfo, setIsWantMoreInfo] = useState(false)
  const [ticketData, setTicketData] = useState<TicketDataRow[]>([])
  const { qrCode } = useParams()
  const navigate = useNavigate()
  const { setAlert } = useContext(AlertContext)
  const { openModal, isModalOpen, setOnConfirm } = useContext(ModalContext)
  const [accommodationNumber, setAccommodationNumber] = useState('')
  const [accommodationErrorMsg, setAccommodationErrorMsg] = useState('')

  const setFormattedAccommodationNumber = (value: string) => {
    const formattedValue = formatEntrantCode(value)
    setAccommodationNumber(formattedValue)
  }

  const formControl = useForm<PreRegisForm>({
    defaultValues: createFromInitialValues(),
    resolver: yupResolver(createYupSchema({ formRows })),
  })

  const onSubmit = async () => {
    if (!!!qrCode) return

    formControl.handleSubmit(async (data) => {
      setIsLoading(true)
      if (!isDisabledForm) {
        console.log('submit form')

        const body = formatFormValuesToRequestBody({
          formControl,
          formRows,
        })

        if (body.GENDER) {
          body.GENDER = String(body.GENDER).toLowerCase()
        }

        body.DATA_012 = isWantMoreInfo

        const response = await regis(qrCode, body)

        if (response.statusCode !== 0) {
          setAlert({
            message: String(response?.errorMessage),
            alertType: 'error',
          })
          setIsLoading(false)
          return
        } else {
          setIsDisabledForm(true)
        }
      }

      if (!!accommodationNumber) {
        console.log('submit accommodation')

        const accommodationResponse = await regisAddOn(
          qrCode,
          accommodationNumber,
          {}
        )

        if (accommodationResponse.statusCode === 122) {
          setAccommodationErrorMsg(
            `This accommodation' entrant code has already fully registered for all guests`
          )
          setIsLoading(false)
          return
        }

        if (accommodationResponse.statusCode !== 0) {
          setAccommodationErrorMsg(
            `This accommodation' entrant code was not found`
          )
          setIsLoading(false)
          return
        }
      }

      setIsLoading(false)
      navigate(`/thankyou/${qrCode}`)
    })()
  }

  useEffect(() => {
    const check = async (code: string) => {
      const response = await checkCode(code)
      const rawData = response?.data

      if (response?.statusCode === 110) {
        navigate(`/thankyou/${qrCode}`)
      }

      setTicketData([
        {
          tittle: 'Ticket Details',
          value: '',
        },
        {
          tittle: 'Ticket Type:',
          value: rawData?.ticketTypeDetail?.name,
          isNotBreakAll: true,
        },
        {
          tittle: 'Entrant Code:',
          value: rawData?.dataTicket?.code,
        },
      ])
    }

    if (!!!qrCode) {
      navigate('/')
      return
    }

    check(qrCode)
  }, [])

  useEffect(() => {
    setAccommodationErrorMsg('')
  }, [accommodationNumber])

  return (
    <>
      <Stack direction={'column'} spacing={2}>
        <TicketData
          showImage
          ticketImage={ticketImage}
          tittle="Your ticket details"
          rows={ticketData}
        />
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="overline1">
            {`Fill in your information below. Make sure the name matches what’s printed in your ID and that your email address is active.`}
          </Typography>
        </Box>
        <div className="flex justify-end">
          <Typography
            sx={{
              color: 'secondary.main',
              textDecorationLine: 'underline',
              cursor: 'pointer',
            }}
            variant="overline1"
            onClick={() => {
              if (!isDisabledForm) {
                openModal({
                  type: 'confirm',
                  message: {
                    icon: trashIcon,
                    tittle: 'Clear all data?',
                    description: 'All answers will be cleared',
                  },
                  onConfirm: () => {
                    formControl.reset({ ...createFromInitialValues() })
                  },
                })
              }
            }}
          >
            Clear All
          </Typography>
        </div>
        <Box>
          <FormBuilder
            key={isModalOpen ? 'force' : 're-render'} // force re-render
            formUiSettings={{ rowInputVariant: 'outlined' }}
            formRows={formRows}
            formControl={formControl}
            isDisabled={isDisabledForm}
          />
          <CustomDivider
            tittle={
              'This section is to pre-register your Boutique Tents,\n Boutique RVs and General Camping for Wonderfruit 2023.'
            }
            sx={{ mt: 1.5 }}
          />
          <TextField
            fullWidth
            size="small"
            sx={{ mt: 1.5 }}
            label="Enter your accommodations Entrant Code"
            value={accommodationNumber}
            helperText={
              !!accommodationErrorMsg ? accommodationErrorMsg : undefined
            }
            error={!!accommodationErrorMsg}
            onChange={(e) => {
              setFormattedAccommodationNumber(e.target.value)
            }}
          />
        </Box>
        <Stack spacing={2}>
          <FormAcceptCheckBox
            isAcceptPolicy={isAcceptPolicy}
            setIsAcceptPolicy={setIsAcceptPolicy}
            disabled={isDisabledForm}
          />
          <CheckBoxWithLabel
            isCheck={isWantMoreInfo}
            setIsCheck={setIsWantMoreInfo}
            label={
              'I want to opt-in for Marketing Communications necessary to gain access to private sales, early access and more'
            }
            disabled={isDisabledForm}
          />
          <div className="flex justify-center">
            <Button
              variant="contained"
              onClick={onSubmit}
              fullWidth
              disabled={
                !isAcceptPolicy ||
                (accommodationNumber.length !== 0 &&
                  accommodationNumber.split('-').join('').length !== 9)
              }
            >
              {'Submit'}
            </Button>
          </div>
        </Stack>
      </Stack>
    </>
  )
}

export default RegistrationForm

// test pipeline
