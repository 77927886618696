import React from 'react'
import notFoundImg from 'assets/images/404.png'
import { Box, Typography } from '@mui/material'

const NotFound: React.FC = () => {
  return (
    <div className="h-[100vh] flex flex-col items-center justify-center text-white">
      <img src={notFoundImg} className="max-h-[400px] p-8" />
      <Typography variant="h4">Page Not Found</Typography>
    </div>
    // <div className="flex flex-col">
    //   <Typography variant="h1">h1</Typography>
    //   <Typography variant="h2">h2</Typography>
    //   <Typography variant="h3">h3</Typography>
    //   <Typography variant="h4">h4</Typography>
    //   <Typography variant="h5">h5</Typography>
    //   <Typography variant="h6">h6</Typography>
    //   <Typography variant="subtitle1">subtitle1</Typography>
    //   <Typography variant="subtitle2">subtitle2</Typography>
    //   <Typography variant="body1">body1</Typography>
    //   <Typography variant="body2">body2</Typography>
    //   <Typography variant="overline1">overline1</Typography>
    //   <Typography variant="overline2">overline2</Typography>
    //   <Typography variant="caption">caption</Typography>
    // </div>
  )
}

export default NotFound
