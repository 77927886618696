import { Button } from '@mui/material'
import QrScanner from 'qr-scanner'
import React from 'react'

type props = {
  isDisabled: boolean
  setOrderNumber: React.Dispatch<React.SetStateAction<string>>
}

const UploadQrCodeButton: React.FC<props> = ({
  isDisabled,
  setOrderNumber,
}) => {
  return (
    <Button
      variant="outlined"
      component="label"
      disabled={isDisabled}
      sx={{ width: '200px', backgroundColor: '#FFF', color: '#000' }}
    >
      Upload QR code
      <input
        hidden
        accept="image/*"
        type="file"
        onChange={({ target }) => {
          const file = target.files ? target?.files[0] : undefined
          if (!file) {
            return
          }
          QrScanner.scanImage(file, { returnDetailedScanResult: true })
            .then((result) => {
              if (result.data && result.data !== '') {
                setOrderNumber(result.data)
              }
              console.log('result', result)
            })
            .catch((e) => console.log('e', e))
        }}
      />
    </Button>
  )
}

export default UploadQrCodeButton
