import { Button, Container, Paper, Stack, Typography } from '@mui/material'
import logoImg from 'assets/images/logo.png'

import ContentTextTitle from 'components/ContentTextTitle/ContentTextTitle'
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar'
import DynamicModal from 'components/DynamicModal/DynamicModal'

import { Loading } from 'components/Loading/Loading'
import LogoBrand from 'components/LogoBrand/LogoBrand'
import { AlertContext } from 'context/AlertContext'

import { LoadingContext } from 'context/LoadingContext'
import React, { useContext, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

export const ContainerLayout: React.FC = () => {
  const location = useLocation()
  const { isLoading } = useContext(LoadingContext)
  const { alertData, setAlert } = useContext(AlertContext)

  return (
    <>
      <Loading isLoading={isLoading} />
      <DynamicModal />
      <CustomSnackbar alertData={alertData} setAlertData={setAlert} />
      <Container maxWidth="sm" sx={{ pb: 7 }}>
        <LogoBrand image={logoImg} />
        <ContentTextTitle
          title={
            location.pathname.includes('thankyou')
              ? `Thank you for pre-registering for\nWonderfruit 2023`
              : `Pre-register your\nWonderfruit 2023 ticket`
          }
          subTitle={
            location.pathname.includes('thankyou')
              ? undefined
              : 'It only takes 2 minutes to pre-register'
          }
          sx={{
            color: 'text.secondary',
            fontSize: '24px',
            lineHeight: '34px',
          }}
        />
        <Outlet />
      </Container>
    </>
  )
}

export const BgLayout: React.FC = () => {
  const location = useLocation()
  useEffect(() => {
    document.getElementById('root')?.scrollIntoView({ behavior: 'auto' })
  }, [location])

  return (
    <div className="min-h-[100vh] bg-[#F0ECE5]">
      <Outlet />
    </div>
  )
}

export const PaperLayout: React.FC = () => {
  return (
    <Paper
      elevation={0}
      sx={{
        p: {
          xs: 2,
          sm: 4.5,
        },
      }}
    >
      <Outlet />
    </Paper>
  )
}
