import { Box, SxProps, Theme, Typography } from '@mui/material'

export type TicketDataRow = {
  tittle: string
  value?: string
  isNotBreakAll?: boolean
}

type props = {
  tittle?: string
  showImage?: boolean
  ticketImage?: string
  sx?: SxProps<Theme>
  rows: TicketDataRow[]
}

const TicketData: React.FC<props> = ({
  tittle,
  showImage = false,
  ticketImage,
  sx,
  rows,
}) => {
  return (
    <>
      <Box sx={{ ...sx }}>
        {!!tittle && (
          <div className="flex justify-center mb-4">
            <Typography variant="h5" sx={{ fontSize: '22px', fontWeight: 700 }}>
              {tittle}
            </Typography>
          </div>
        )}
        <div className="flex">
          {showImage && !!ticketImage && (
            <img
              src={ticketImage}
              className={`hidden sm:inline mr-4 w-[150px] h-fit rounded-lg`}
            />
          )}
          <div className="w-full">
            {rows.map((row) => (
              <div className="flex mt-1">
                {!!row.value ? (
                  <>
                    <Typography variant="overline2" sx={{ width: 120 }}>
                      {row.tittle}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className="flex-1"
                      sx={{ wordBreak: row.isNotBreakAll ? '' : 'break-all' }}
                    >
                      {row.value}
                    </Typography>
                  </>
                ) : (
                  <Typography variant="body2" sx={{ pb: 0 }}>
                    {row.tittle}
                  </Typography>
                )}
              </div>
            ))}
          </div>
        </div>
      </Box>
    </>
  )
}

export default TicketData
