import {
  Box,
  Button,
  Grid,
  Link,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import TicketData, { TicketDataRow } from 'components/TicketData/TicketData'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { checkAddOn, checkCode } from 'service/PreregisService'
import ticketImage from 'assets/images/ticketImg.png'
import { QRCodeGenerator } from 'components/QRCodeGenerator/QRCodeGenerator'

const Thankyou: React.FC<{}> = ({}) => {
  const [ticketData, setTicketData] = useState<TicketDataRow[]>([])
  const [isShowQrCode, setIsShowQrCode] = useState<boolean>(true)
  const [isAccom, setIsAccom] = useState<boolean>(false)
  const { qrCode } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    const check = async (code: string) => {
      const response = await checkCode(code)
      const rawData = response?.data

      if (
        response?.statusCode !== 110 &&
        code.replace(/-/g, '').toUpperCase() !== 'ADMINTEST'
      ) {
        navigate('/')
        return
      }

      let newTicketData: TicketDataRow[] = [
        {
          tittle: 'Ticket Details',
          value: '',
        },
        {
          tittle: 'Ticket Type:',
          value: rawData?.ticketTypeDetail?.name,
          isNotBreakAll: true,
        },
        {
          tittle: 'Entrant Code:',
          value: rawData?.dataTicket?.code,
        },
        {
          tittle: 'Full Name:',
          value:
            rawData?.saveData?.FIRST_NAME + ' ' + rawData?.saveData?.LAST_NAME,
        },
        {
          tittle: 'E-mail:',
          value: rawData?.saveData?.EMAIL,
        },
      ]

      if (!!rawData?.saveData?.ADD_ON_CODE) {
        setIsAccom(true)
        newTicketData = newTicketData.concat([
          {
            tittle: ' ',
            value: ' ',
          },
          {
            tittle: 'Accommodation Details',
            value: '',
          },
          {
            tittle: 'Ticket Type:',
            value: rawData?.saveData?.ADD_ON_NAME,
            isNotBreakAll: true,
          },
          {
            tittle: 'Entrant Code:',
            value: rawData?.saveData?.ADD_ON_CODE,
          },
        ])
      }
      setTicketData(newTicketData)
    }

    if (!!!qrCode) {
      navigate('/')
      return
    }

    check(qrCode)
  }, [])

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          mt: 3,
          p: {
            xs: 2,
            sm: 4.5,
          },
        }}
      >
        <Stack spacing={3}>
          <Typography
            variant="h5"
            sx={{
              textAlign: 'center',
              fontSize: '22px',
              lineHeight: '26px',
              fontWeight: 'bold',
            }}
          >
            Download the Wonder App and stay up to date on the latest news from
            The Fields
          </Typography>
          <TicketData ticketImage={ticketImage} rows={ticketData} showImage />
          <Stack sx={{ mt: 2 }} spacing={2}>
            <Typography variant="body1">
              You’ve made it to the end. But before you go, take note of a
              couple of things:
            </Typography>
            <Typography variant="body1">
              1. Now that you’ve pre-registered, you’ll get an email with a QR
              code. Bring that QR code and your ID to the main entrance of
              Wonderfruit, where you’ll get an RFID wristband. Don’t lose this
              wristband, as this is where you’ll be loading what you want to
              spend at the event, your tickets, and more.
            </Typography>
            <Typography variant="body1">
              2. We have a lot more essential updates from The Fields to come.
              To not miss a thing, make sure you’re subscribed to our
              newsletter, and that we’re added to your contacts so we don’t land
              in your junk folder.
            </Typography>
            <Typography variant="body1">
              3. As we get closer to the gates opening, our full program will be
              released on our app. We recommend downloading it in advance in
              case the matrix gets overloaded at the door. You can download it
              now and it will continue to update as the date draws near.
            </Typography>
            <Typography variant="body1">
              4. Don’t forget to follow our social media channel so you don’t
              miss any wonder heading your way: Facebook, Instagram, Twitter.
            </Typography>
            <Typography variant="body1">
              {
                '5. If you booked on-site accommodations, make sure you pre-register your tent or RV, and those who will be with you.'
              }
            </Typography>
            <Typography variant="body2">See you in The Fields.</Typography>
          </Stack>
          {isShowQrCode && (
            <div className="flex flex-col items-center pb-4">
              <Typography sx={{ fontSize: '16px', mb: 1 }}>
                Your QR Code
              </Typography>
              <QRCodeGenerator
                data={String(qrCode)}
                options={{ margin: 0, width: 150 }}
              />
            </div>
          )}
        </Stack>
      </Paper>
      {!isAccom && (
        <Paper
          elevation={0}
          sx={{
            mt: 3,
            p: {
              xs: 2,
              sm: 4.5,
            },
          }}
        >
          <Stack spacing={2}>
            <Typography
              variant="subtitle2"
              textAlign="center"
              sx={{ fontSize: '22px' }}
            >
              Pre-register your accommodation
            </Typography>
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                // alert('not implemented yet 🙏')
                navigate(`/accommodation/${qrCode}`)
              }}
            >
              Accommodation
            </Button>
          </Stack>
        </Paper>
      )}
    </>
  )
}

export default Thankyou
