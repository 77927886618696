import { Box, Divider, Stack, SxProps, Theme, Typography } from '@mui/material'
import React from 'react'

const CustomDivider: React.FC<{ tittle: string; sx?: SxProps<Theme> }> = ({
  tittle,
  sx,
}) => {
  return (
    <Box sx={{ ...sx }}>
      <Divider>
        <Typography
          variant="overline1"
          sx={{ fontSize: '12px', whiteSpace: 'pre' }}
        >
          {tittle}
        </Typography>
      </Divider>
    </Box>
  )
}

export default CustomDivider
