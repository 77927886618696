export const formatEntrantCode = (input: string): string => {
  const alphabeticOnly = input.replace(/[^a-zA-Z0-9]/g, '').toUpperCase()
  const maxLengthValue = alphabeticOnly.substring(0, 9)
  const groups = maxLengthValue.match(/.{1,3}/g)
  const formattedValue = groups ? groups.join('-') : ''
  return formattedValue
}

export const formatOrderNo = (input: string): string => {
  const alphabeticOnly = input.replace(/[^a-zA-Z0-9]/g, '').toUpperCase()
  const formattedValue = alphabeticOnly.substring(0, 7)
  return formattedValue
}
