import { Alert, AlertColor, Snackbar } from '@mui/material'

const CustomSnackbar: React.FC<{
  alertData: {
    message: string
    alertType: AlertColor
  }
  setAlertData: React.Dispatch<
    React.SetStateAction<{
      message: string
      alertType: AlertColor
    }>
  >
}> = ({ alertData, setAlertData }) => {
  return (
    <Snackbar
      open={!!alertData.message}
      autoHideDuration={6000}
      onClose={() => {
        setAlertData((prev) => {
          return { ...prev, message: '' }
        })
      }}
      key={'topright'}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert
        onClose={() => {
          setAlertData((prev) => {
            return { ...prev, message: '' }
          })
        }}
        variant="filled"
        severity={alertData.alertType}
        sx={{ width: '100%' }}
      >
        {alertData.message}
      </Alert>
    </Snackbar>
  )
}

export default CustomSnackbar
